.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke-miterlimit: 10;
    position: relative;
    margin: 0 auto;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.pulse-error {
    content: '';
    width: 20px;
    height: 20px;
    border: 15px solid #e76f51;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    left: -10px;
    animation: pulsate infinite 1s;
}

.pulse-connect {
    content: '';
    width: 20px;
    height: 20px;
    border: 15px solid #2a9d8f;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    left: -10px;
    animation: pulsate infinite 1s;
}

.pulse-warning {
    content: '';
    width: 50px;
    height: 50px;
    border: 10px solid #e9c46a;
    border-radius: 50%;
    position: absolute;
    top: -13px;
    left: -13px;
    animation: pulsate infinite 1s;
}


@keyframes pulsate {
    0% {
        -webkit-transform: scale(1, 1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}

.custom-react-tooltip {
    max-width: 50rem !important;
    border: #4bb71b
}

@keyframes goSmoke {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(2, 2) translateY(-20px) rotate(30deg);
    }
}

@keyframes goWindow {
    0% {
        background-color: #F5F19A;
    }

    100% {
        background-color: #D3BD54;
    }
}

@keyframes goWheels {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes goPackage {
    83% {
        -webkit-transform: translateX(120px);
    }

    95% {
        -webkit-transform: translateX(130px) translateY(25px) rotate(90deg);
    }

    100% {
        -webkit-transform: translateX(130px) translateY(25px) rotate(90deg);
    }
}


#wraper {
    width: 400px;
    height: 350px;
    margin: 0 auto;
    position: relative;
    /*border: 2px solid black;*/
}

.stand {
    width: 10px;
    height: 13px;
    position: absolute;
    background-color: #BCBEC0;
    top: 279px;
}

#stand1 {
    left: 251px;
}

#stand2 {
    left: 321px;
}

#band {
    width: 100px;
    height: 13px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    background-color: #58595B;
    top: 266px;
    left: 241px;
}

.wheel {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #414042;
    position: absolute;
    /*-webkit-animation: goWheels 0.5s linear infinite normal;*/
}

.wheel:after {
    content: "";
    display: block;
    width: 12px;
    height: 2px;
    background-color: #F5F19A;
    position: absolute;
    top: 5px;
}


#wheel1 {
    left: 5px;
    animation: goWheels 0.5s 0.1s linear infinite normal;
}

#wheel2 {
    left: 31px;
    animation: goWheels 0.5s 0.2s linear infinite normal;
}

#wheel3 {
    left: 56px;
    animation: goWheels 0.5s 0.3s linear infinite normal;
}

#wheel4 {
    left: 79px;
    animation: goWheels 0.5s 0.4s linear infinite normal;
}

.package {
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #403F63;
    top: 246px;
    left: 220px;
    animation: goPackage 2s 0.3s linear infinite normal;
}



#mainFactory {
    width: 150px;
    height: 100px;
    background-color: #CFB499;
    position: absolute;
    top: 195px;
    left: 91px;
}

#door {
    width: 25px;
    height: 40px;
    background-color: #403F63;
    position: absolute;
    top: 255px;
    left: 155px;
}

#door:after {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #F5F19A;
    position: absolute;
    top: 21px;
    left: 15px;

}

.window {
    width: 25px;
    height: 25px;
    background-color: #F5F19A;
    position: absolute;
    border: 2px solid #403F63;
    transition: background-color 0.3s linear;
    animation: goWindow 3s infinite alternate;
}

#window1 {
    top: 216px;
    left: 109px;
}

#window2 {
    top: 216px;
    left: 197px;
}

#roof1 {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 0 33px 64px;
    border-color: transparent transparent #403F63 transparent;
    position: absolute;
    top: 162px;
    left: 91px;
}

#roof2 {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 0 44px 86px;
    border-color: transparent transparent #403F63 transparent;
    position: absolute;
    top: 151px;
    left: 155px;
}

#chim1 {
    width: 25px;
    height: 80px;
    background-color: #E2C4A5;
    position: absolute;
    top: 115px;
    left: 155px;
}

#chim2 {
    width: 25px;
    height: 102px;
    background-color: #E2C4A5;
    position: absolute;
    top: 93px;
    left: 198px;
}

.stripe {
    width: 25px;
    height: 10px;
    background-color: #BE1E2D;
    position: absolute;
}

#stripe1 {
    top: 128px;
    left: 155px;
}

#stripe2 {
    top: 145px;
    left: 155px;
}

#stripe3 {
    top: 105px;
    left: 198px;
}

#stripe4 {
    top: 123px;
    left: 198px;
}

.smoke {
    width: 19px;
    height: 18px;
    position: absolute;
    opacity: 0;
}

.sCloud {
    width: 60%;
    height: 0.7em;
    background-color: #E6E7E8;
    position: absolute;
    border-radius: 18px;
}

#smoke1 {
    top: 91px;
    left: 157px;
    transition-property: opacity, webkit-transform;
    transition-duration: 1s, 1s;
    transition-timing-function: linear, linear;
    animation: goSmoke 2s 0.3s infinite normal;
}

#smoke2 {
    top: 71px;
    left: 200px;
    transition-property: opacity, webkit-transform;
    transition-duration: 1s, 1s;
    transition-timing-function: linear, linear;
    animation: goSmoke 2s 1.3s infinite normal;
}

#sCloud1,
#sCloud4 {
    top: 2px;
}

#sCloud2,
#sCloud5 {
    left: 6px;
}

#sCloud3,
#sCloud6 {
    top: 6px;
    left: 7px;
}

#tree {
    width: 54px;
    height: 87px;
    position: absolute;
    top: 208px;
    left: 60px;
}

#branch {
    width: 9px;
    height: 37px;
    position: absolute;
    background-color: #754C29;
    top: 50px;
    left: 23px;
}

.leaves {
    width: 35px;
    height: 35px;
    background-color: #8DC63F;
    position: absolute;
    border-radius: 18px;
}

#leaves1 {
    top: 20px;
}

#leaves2 {
    left: 10px;
}

#leaves3 {
    top: 20px;
    left: 19px;
}

#data-1 {
    animation-name: drop;
    bottom: 100%;
    pointer-events: none;
    animation: drop 1s linear infinite;
}

#data-2 {
    animation-name: drop;
    bottom: 100%;
    pointer-events: none;
    animation-delay: 0.5s;
    animation: drop 2s linear infinite;
}

#data-3 {
    animation-name: drop;
    bottom: 100%;
    pointer-events: none;
    animation-delay: 0s;
    animation: drop 3s linear infinite;
}

#data-4 {
    animation-name: drop;
    bottom: 100%;
    pointer-events: none;
    animation-delay: 4s;
    animation: drop 1.5s linear infinite;
}

#data-hor-1 {
    animation-name: line;

    pointer-events: none;
    animation: line 1s linear infinite;
    animation-delay: 0s;
}

#data-hor-2 {
    animation-name: line;

    pointer-events: none;
    animation: line 1s linear infinite;
    animation-delay: 0.2s;
}

#data-hor-3 {
    animation-name: line;

    pointer-events: none;
    animation: line 1s linear infinite;
    animation-delay: 0.4s;
}

@keyframes drop {
    100% {
        transform: translateY(0vh);

    }

    75% {
        transform: translateY(0vh);

    }

    0% {
        transform: translateY(500%);
    }
}

@keyframes line {
    0% {
        transform: translateX(0vw);

    }

    100% {
        transform: translateX(20vw);
    }
}