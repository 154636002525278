@tailwind base;
@tailwind components;
@tailwind utilities;


.react-datepicker__day-name {
    color: white !important;
}

.react-datepicker__current-month {
    color: white !important;
}

.react-datepicker__header {
    background-color: rgb(59 130 246) !important;
}

.loading-visible .loading-bg {
    animation: loading-anim 1.1s linear forwards 0.3s;
}

@keyframes loading-anim {
    0% {
        transform: translateY(70px) skewX(0) rotate(0) scaleY(0);
    }

    31% {
        transform: translateY(70px) skewX(-1deg) rotate(-1deg) scaleY(0.26);
    }

    42% {
        transform: translateY(70px) skewX(-2deg) rotate(-2deg) scaleY(0.38);
    }

    56% {
        transform: translateY(70px) skewX(-5deg) rotate(-5deg) scaleY(0.55);
    }

    65% {
        transform: translateY(70px) skewX(-5deg) rotate(-5deg) scaleY(0.66);
    }

    88% {
        transform: translateY(40px) skewX(-2deg) rotate(-2deg) scaleY(0.95);
    }

    100% {
        transform: translateY(40px) skewX(0) rotate(0) scaleY(1.05);
    }
}

.loading-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    z-index: -1;
    transform-origin: 50% 100%;
    transform: translateY(70px) skewX(0) rotate(0) scaleY(0);
}

.loading {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    position: fixed;
    z-index: 999;
    background-color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
}

.loading-visible .loading-logo {
    visibility: visible;
    opacity: 1;
}

.loading-logo {
    position: relative;
    transition: opacity 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) 0ms,
        visibility 0s cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

.loading-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    z-index: -1;
    transform-origin: 50% 100%;
    transform: translateY(70px) skewX(0) rotate(0) scaleY(0);
}

.loading-logo {
    position: relative;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.25, 0.25, 0.75, 0.75),
        visibility 0.4s cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

.loading-logo svg {
    width: 250px;
    height: 60px;
}

.loading-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    z-index: -1;
    transform-origin: 50% 100%;
    transform: translateY(70px) skewX(0) rotate(0) scaleY(0);
}

.loading-visible .loading-logo {
    visibility: visible;
    opacity: 1;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.visibleSection {
    max-height: 500rem !important;
}

.hideSection {
    height: auto;
    max-height: 0rem;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
}

.toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #68D391;
}

.toggle-checkbox:checked+.toggle-label {
    @apply: bg-green-400;
    background-color: #68D391;
}

.bg-pattern-left {
    background-image: url("../img/pattern-left.png");
    background-position: left 0 bottom 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-pattern-right {
    background-image: url("../img/pattern-right.png");
    background-position: right 0 bottom 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-pattern-right-dark {
    background-image: url("../img/pattern-right-dark.png");
    background-position: right 0 bottom 0;
    background-repeat: no-repeat;
    background-size: cover;
}

/*Tree*/
.tree ul {
    margin: 0 0 0 15em;
    padding: 0;
    list-style: none;
    color: #369;
    position: relative;
}

.tree:before,
.tree ul:before {
    content: "";
    display: block;
    width: 0px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 1px solid;
    background: white;
    z-index: -1;
}

.tree li {
    font-weight: bold;
    line-height: 3em;
    margin: 0;
    padding: 1.5em 0 0 2em;
    position: relative;
}

.tree li:before {
    border-top: 1px solid;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    margin-top: 1em;
    position: absolute;
    top: 1.5em;
    width: 2em;
}

.tree li:last-child:before {
    background: white;
    bottom: 0;
    height: auto;
    top: 1.5em;
}

.tree>li:before {
    border-top: none !important;
}

ul.tree {
    margin-left: 0px;
}

ul.tree:before {
    border-left: none;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    width: 100%;
}

.react-grid-item.react-grid-placeholder {
    background-color: #3b82f6;
}